import moment from "moment";
import { storage, auth } from "../firebase/firebase";

const store = async (file: any) => {
    const user = auth.currentUser;
    let ref = storage
        .ref(user && user.uid ? `users/${user.uid}` : "anonymous")
        .child(moment().toISOString() + Math.random());

    let snapshot = await ref.put(file);
    return await snapshot.ref.getDownloadURL();
};

export default {
    store
};
