import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import './dashboard-view.scss';
import Loading from '../../components/Loading'
import api from '../../api';
import {SurveyProps} from '../../api/survey'
import { full_name } from '../../api/auth';

const DashboardView = (props:any) => {
    const [fetching, setFetching] = useState(true)
    const [surveys, setSurveys] = useState([])

    useEffect(() => {
        api.survey.list().then((data:any) => {
            setSurveys(data);
            setFetching(false)
        }).catch(e => console.log(e))
    })

    if(fetching){
        return <Loading />
    }

    return (
        <div className="dashboard-view">
            <div className="container">
                <h1>{surveys.length} inschrijvingen</h1>
                <table>
                    <thead>
                        <tr>
                            <td>Naam</td>
                            <td>Club</td>
                            <td>Stad</td>
                            <td>Aangemaakt op</td>
                        </tr>
                    </thead>
                    <tbody>
                        {surveys.map((survey: SurveyProps)=> (
                            <tr key={survey.id}>
                                <td>{full_name(survey.user)}</td>
                                <td>{survey.questions[0].answer}</td>
                                <td>{survey.address.city}</td>
                                <td>{survey.created_at}</td>
                            </tr>
                        ))}
                     
                    </tbody>
                
                </table>
            </div>
        </div>
    )
}

export default connect(
    (state:any) => ({
        user: state.auth.user
    }),
    {}
)(DashboardView)
