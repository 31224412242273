import firebase from "firebase";
import config from "./config.json";

firebase.initializeApp(config);
firebase.analytics();

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export default firebase;
