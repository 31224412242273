import auth, { AuthState } from "./auth";

export interface ApplicationState {
    auth: AuthState;
}

const rootReducer = {
    auth,
};

export default rootReducer;
