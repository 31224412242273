import React from 'react'
import './loading.scss';

const Loading = () => {
    return (
       <div className="loading">
           <i className="fal fa-spin fa-spinner-third"></i>
           <p>Een ogenblik geduld aub.</p>
       </div>
    )
}

export default Loading
