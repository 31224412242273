import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import auth, { AuthState } from './reducers/auth';
import rootReducer from './reducers';


export interface AppState {
    auth: AuthState,
}

export default createStore(combineReducers(rootReducer), applyMiddleware(ReduxThunk));
