import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { connect } from 'react-redux'
import SurveyView from './views/SurveryView/SurveyView';
import LoginView from './views/LoginView/LoginView';
import { auth } from './firebase/firebase';
import DashboardView from './views/DashboardView/DashboardView';
import store from './store';
import { setProfile } from './reducers/auth';
import Loading from './components/Loading';

const RedirectRoute = ({ component: Component, auth, ...rest } : any) => (
  <Route
    {...rest}
    auth={auth}
    render={(props:any) => (!auth ? <Redirect to="/login" /> : <Component {...props} />   )}
  />
);
const Routes = (props:any) => {
    const [isEmail, setIsEmail] = useState(false)
    const [fetching, setFetching] = useState(true)
    useEffect(() => {
        auth.onAuthStateChanged((user:any) => {
            if(user){
                user.providerData.forEach((profile:any) => {
                    if(profile.providerId === 'password'){
                        setIsEmail(true)
                        store.dispatch(setProfile(user))
                    }
                })
                setFetching(false);
            }else{
                store.dispatch(setProfile({}))
                setFetching(false)
            }
        })
    }, [])

    if(fetching){
        return <Loading />
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={SurveyView} />
                <Route exact path="/login" component={LoginView} />
                <RedirectRoute auth={isEmail} exact path="/dashboard" component={DashboardView} />
            </Switch>
        </Router>
    )
}

export default connect(
    (state:any) => ({
        user: state.auth.user
    }),
    {}
)(Routes)
