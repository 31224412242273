import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import './login-view.scss';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import { auth } from '../../firebase/firebase';
import api from '../../api';
import store from '../../store';
import { setProfile } from '../../reducers/auth';

const LoginView = (props: any) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [fetching, setFetching] = useState(true)

    useEffect(() => {
        console.log(props.user)
        if(props.user && props.user.id){
            props.history.push('/dashboard')
        }
        setFetching(false);
    }, [])

    const handleSubmit = (e:any) => {
        if(e){
            e.preventDefault()
        }
        setFetching(true)
        api.auth.login(email, password)
            .then(() => {
                let user = auth.currentUser;
                store.dispatch(setProfile(user))
                props.history.push('/dashboard')
                setFetching(false)
            }).catch((e) => {
                alert('Error')
                setFetching(false)
            })
    }
    return (
        <div className="login-view">
            <div className="container">
                <div className="card">
                    <form onSubmit={handleSubmit}>
                        <Input 
                            value={email}
                            name="email"
                            type="email"
                            onChange={(email)  =>setEmail(email)}
                            label="E-mail"
                            placeholder=""
                        />
                        <Input 
                            value={password}
                            name="password"
                            type="password"
                            onChange={(password)  => setPassword(password)}
                            label="Wachtwoord"
                            placeholder=""
                        />
                        <Button isLoading={fetching} onClick={handleSubmit}>
                            Inloggen
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(
    (state:any) => ({
        user: state.auth.user
    }),
    {}
)(LoginView)
