import firebase from '../firebase/firebase';

export const full_name = (user:any) => {
    if(user.affix){
        return `${user.first_name} ${user.affix} ${user.last_name}`
    }
    return `${user.first_name} ${user.last_name}`
}

export const registerAnonymous = async () => firebase.auth().signInAnonymously();

export const login = async (email:string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password)