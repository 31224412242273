import { createAction, handleActions } from 'redux-actions';

export interface UpdateProfileProps {
  id: number;
  name: string;
  email: string;
  avatar: string;
  roles: []
}

export interface AuthState {
    user: any
}

export const setProfile = createAction('SET_PROFILE');
// export const updateProfile = createAction('UPDATE_PROFILE');

const authReducer = handleActions(
    {
        [setProfile.toString()]: (state:AuthState, action:any) => {
            return {
                ...state,
                user: action.payload
            }
        },
    },
  {
    user: undefined
  }
);

export default authReducer;
