import React, { useState } from "react";
import "./file-picker.scss";
import api from "../../../api";

type FilePickerProps = {
    value: any;
    onChange: (value: any) => void;
    label: string;
};

const FilePicker = ({ value, onChange, label }: FilePickerProps) => {
    const [fetching, setFetching] = useState(false);
    const handleChange = (e: any) => {
        if (e.target.files) {
            setFetching(true);
            const file = e.target.files[0];
            api.files
                .store(file)
                .then((url: string) => {
                    onChange(url);
                    console.log(url);
                    setFetching(false);
                })
                .catch(() => {
                    setFetching(false);
                });
        }
    };
    return (
        <div className="file-picker">
            <div className="label">
                {label || ""}
                {value ? (
                    <div className="file-picker_preview">
                        <img src={value} alt="" />
                        <div onClick={() => onChange("")} className="clear">
                            <i className="fal fa-times"></i>
                            <span>Verwijderen</span>
                        </div>
                    </div>
                ) : fetching ? (
                    <i className="fal fa-spin fa-spinner-third"></i>
                ) : (
                    <input type="file" onChange={handleChange} />
                )}
            </div>
        </div>
    );
};

export default FilePicker;
