import moment from "moment";
import { db, auth } from "../firebase/firebase";

type UserProps = {
    email: string;
    first_name: string;
    affix?: string;
    last_name: string;
    date_of_birth: string;
    telephone: string;
    avatar?: string;
};

type AddressProps = {
    street: string;
    zipcode: string;
    city: string;
};

export type SurveyProps = {
    user: UserProps;
    address: AddressProps;
    questions: any[];
    created_at?: string;
    id?: string;
};

export default {
    list: async () => {
        return db
            .collection("survey")
            .get()
            .then(snapshot => {
                return snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            });
    },
    add: async (data: SurveyProps) => {
        // let doc: any = await db.collection("survey").doc(data.user.email);
        // if (doc.exists) {
        //     throw new Error("Already exists");
        // }
        return db
            .collection("survey")
            .doc(data.user.email)
            .set({
                ...data,
                created_at: moment().format("DD-MM-YYYY"),
                created_by: auth.currentUser?.uid
            });
    }
};
