import React from "react";
import "./input.scss";
type InputProps = {
    type?: string;
    value?: string;
    onChange: (text: string) => void;
    placeholder?: string;
    label?: string;
    name?: string;
    required?: boolean;
};

const Input = ({
    type,
    value,
    onChange,
    placeholder,
    label,
    name,
    required
}: InputProps) => {
    if (type === "boolean") {
        return (
            <div className="input">
                <label>
                    {label || ""}
                    <div className="input-buttons">
                        <div
                            onClick={() => onChange("ja")}
                            className={`input-button ${value === "ja" &&
                                "input-button_active"}`}
                        >
                            Ja
                        </div>
                        <div
                            onClick={() => onChange("nee")}
                            className={`input-button ${value === "nee" &&
                                "input-button_active"}`}
                        >
                            Nee
                        </div>
                    </div>
                </label>
            </div>
        );
    }

    return (
        <div className="input">
            <label>
                {label || ""}
                <input
                    type={type || "text"}
                    value={value || ""}
                    onChange={(e: any) => onChange(e.target.value)}
                    placeholder={placeholder || ""}
                    name={name}
                    required={required}
                />
            </label>
        </div>
    );
};

export default Input;
