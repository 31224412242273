import { registerAnonymous, login } from "./auth";
import survey from "./survey";
import files from "./files";

export default {
    auth: {
        registerAnonymous,
        login
    },
    survey: {
        list: survey.list,
        add: survey.add
    },
    files
};
