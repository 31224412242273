import React from 'react'
import { withRouter } from 'react-router-dom'
import './button.scss';

type ButtonProps = {
    isLoading?: boolean,
    type?: string,
    icon?: string,
    children: any,
    to?: string,
    onClick?: (event: any) => void,
    brand?: boolean,
    disabled?: boolean,
    history: any;
}

const Button = ({isLoading, brand, onClick, type, icon, children, disabled, to, history}:ButtonProps) => {
    const handleClick = (e: any) => {
        if(onClick){
            onClick(e)
        }else if(to){
            history.push(to)
        }
    }

    return (
        <button 
            disabled={disabled || isLoading} 
            onClick={handleClick} 
            className={`
                button ${type ? 'button-' + type : ''} 
                ${icon ? 'button-has-icon' : ''}
                ${isLoading ? 'button-loading' : ''}
                ${disabled ? 'button-disabled' : ''}
            `}
        >
            {isLoading ? (
                <i className="fal fa-spin fa-spinner-third"></i>
            ) : (
                <>
                    {icon ? (
                        <div className="icon">
                            <i 
                                className={`${brand ? 'fab' : 'fal'} ${icon}`}
                            >
                            </i>
                        </div>
                    ) : false}
                    {children}
                </>
            )}
        </button>
    )
}

export default withRouter(Button)
