import React, { useState, useEffect } from "react";
import "./survey-view.scss";
import api from "../../api";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Loading from "../../components/Loading";
import QUESTIONS from "./questions.json";
import FilePicker from "../../components/Form/FilePicker";
import logo from "../../logo.png";

const SurveyView = () => {
    const [fetching, setFetching] = useState(true);
    const [seeding, setSeeding] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [user, setUser] = useState({
        email: "",
        first_name: "",
        affix: "",
        last_name: "",
        date_of_birth: "",
        telephone: "",
        avatar: ""
    });
    const [address, setAddress] = useState({
        street: "",
        zipcode: "",
        city: ""
    });
    const [questions, setQuestions] = useState(QUESTIONS);

    useEffect(() => {
        api.auth.registerAnonymous().then((user: any) => {
            setFetching(false);
        });
    }, []);

    if (fetching) {
        return <Loading />;
    }

    const handleUser = (key: string, value: string) => {
        setUser({
            ...user,
            [key]: value
        });
    };

    const handleAddress = (key: string, value: string) => {
        setAddress({
            ...address,
            [key]: value
        });
    };

    const handleQuestions = (index: number, value: string) => {
        let data = questions.map((q, count) => {
            if (count === index) {
                return {
                    ...q,
                    answer: value
                };
            }
            return q;
        });
        setQuestions(data);
    };

    const handleSubmit = async () => {
        if (
            !user.first_name ||
            !user.last_name ||
            !user.email ||
            !user.telephone ||
            !user.email
        ) {
            setError("Vul alle basisgegevens in!");
            window.scrollTo(0, 0);
            return;
        }

        if (!address.street || !address.city || !address.zipcode) {
            setError("Vul uw adres in");
            window.scrollTo(0, 0);
            return;
        }

        let answered = true;
        questions.map(q => {
            if (!q.answer) {
                answered = false;
            }
        });

        if (!answered) {
            setError("Vul alle vragen in");
            window.scrollTo(0, 0);
            return;
        }

        setFetching(true);
        api.survey
            .add({
                user,
                address,
                questions
            })
            .then(() => {
                setFetching(false);
                setSuccess(true);
                window.scrollTo(0, 0);
            })
            .catch(e => {
                console.log(e);
                setError("Er is al een aanmelding met " + user.email);
                window.scrollTo(0, 0);
                setFetching(false);
            });
    };

    if (success) {
        return (
            <div className="survey-view">
                <div className="container">
                    <h1>U bent aangemeld!</h1>
                    <h2 className="subtitle">
                        Bedankt voor uw aanmelding, we gaan uw aanmelding
                        verwerken.
                    </h2>
                </div>
            </div>
        );
    }

    return (
        <div className="survey-view">
            <div className="container">
                <div className="survey-view-header">
                    <div className="info">
                        <h1>Vul de volgende velden in</h1>
                        <h2 className="subtitle">
                            Voor opnames zoeken wij fanatieke voetbal supporters
                            die wel tegen een stootje kunnen.
                        </h2>
                    </div>

                    <img className="logo" src={logo} alt="Orange Offenders" />
                </div>

                <div className="survey-view_form">
                    {error && (
                        <div className="error">
                            <p>{error}</p>
                        </div>
                    )}

                    <h3>Basisgegevens</h3>
                    <div className="row">
                        <Input
                            required
                            name="first_name"
                            placeholder="Voornaam"
                            value={user.first_name}
                            label={"Voornaam"}
                            onChange={(value: string) =>
                                handleUser("first_name", value)
                            }
                        />
                        <Input
                            name="affix"
                            placeholder="Tussenvoegsel"
                            value={user.affix}
                            label={"Tussenvoegsel (optioneel)"}
                            onChange={(value: string) =>
                                handleUser("affix", value)
                            }
                        />
                        <Input
                            required
                            name="last_name"
                            placeholder="Achternaam"
                            value={user.last_name}
                            label={"Achternaam"}
                            onChange={(value: string) =>
                                handleUser("last_name", value)
                            }
                        />
                    </div>
                    <Input
                        required
                        name="email"
                        placeholder="Bijvoorbeeld: johndoe@gmail.com"
                        value={user.email}
                        label={"E-mail"}
                        type="email"
                        onChange={(value: string) => handleUser("email", value)}
                    />
                    <Input
                        required
                        name="date_of_birth"
                        placeholder="Geboortedatum"
                        value={user.date_of_birth}
                        label={"Geboortedatum"}
                        type="date"
                        onChange={(value: string) =>
                            handleUser("date_of_birth", value)
                        }
                    />
                    <Input
                        required
                        name="telephone"
                        placeholder="Telefoonnummer"
                        value={user.telephone}
                        label={"Telefoonnummer"}
                        type="tel"
                        onChange={(value: string) =>
                            handleUser("telephone", value)
                        }
                    />
                    <FilePicker
                        label="Profielfoto (optioneel)"
                        value={user.avatar}
                        onChange={(avatar: string) =>
                            handleUser("avatar", avatar)
                        }
                    />

                    <h3>Adres</h3>
                    <Input
                        required
                        name="street"
                        placeholder="Straat + Huisnummer"
                        value={address.street}
                        label={"Straat + Huisnummer"}
                        onChange={(value: string) =>
                            handleAddress("street", value)
                        }
                    />
                    <Input
                        required
                        name="zipcode"
                        placeholder="Postcode"
                        value={address.zipcode}
                        label={"Postcode"}
                        onChange={(value: string) =>
                            handleAddress("zipcode", value)
                        }
                    />
                    <Input
                        required
                        name="city"
                        placeholder="City"
                        value={address.city}
                        label={"Stad"}
                        onChange={(value: string) =>
                            handleAddress("city", value)
                        }
                    />

                    <h3>Vragen</h3>
                    {questions.map((q, index) => (
                        <Input
                            required
                            key={q.label + index}
                            label={q.label}
                            onChange={value => handleQuestions(index, value)}
                            value={q.answer}
                            type={q.type || "text"}
                        />
                    ))}
                    <Button onClick={handleSubmit}>Versturen</Button>
                </div>
            </div>
        </div>
    );
};

export default SurveyView;
